
.loading-page-audit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
  }
  
.loading-page-audit-spinner {
    position: relative;
   width: 11.2px;
   height: 11.2px;
   animation: spinner-o824ag 1s infinite linear;
}

.loading-page-audit-spinner div {
   position: absolute;
   width: 100%;
   height: 100%;
   background: var(--color-lanfia-primary);
   border-radius: 50%;
   animation: spinner-vse6n7 1.25s infinite ease;
   -webkit-animation: spinner-vse6n7 1.25s infinite ease;
}

.loading-page-audit-spinner div:nth-child(1) {
   --rotation: 90;
}

.loading-page-audit-spinner div:nth-child(2) {
   --rotation: 180;
}

.loading-page-audit-spinner div:nth-child(3) {
   --rotation: 270;
}

.loading-page-audit-spinner div:nth-child(4) {
   --rotation: 360;
}

@keyframes spinner-vse6n7 {
   0%, 100% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
   }

   50% {
      transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
   }
}

@keyframes spinner-o824ag {
   to {
      transform: rotate(360deg);
   }
}
